import * as React from 'react';
import Container from 'react-bootstrap/Container';
import { Outlet } from 'react-router-dom';
import Header from '../Header';

const Page = () => {
  return (
    <Container className="px-0 pb-5" fluid>
      <Header />
      <Outlet />
    </Container>
  )
}

export default Page;
