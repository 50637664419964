import * as React from 'react';
// import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container'

import './index.scss';
import logo from '../../../assets/images/logo.png';

const Header = () => {
  return (
    <Navbar expand="lg" className="main-nav">
      <Container className="d-block text-center my-4" style={{ maxWidth: '992px' }}>
        <Navbar.Brand href="/">
          <img className="logo" src={logo} alt="AAD Gems Booster Club" />
        </Navbar.Brand>
        <div>
          <h1 style={{ color: '#ffffff' }}>AAD Gems Booster Club</h1>
          <h4 style={{ color: '#ffffff' }}><em>Supporting the competition teams of <a target="_blank" href="https://allanasacademyofdance.com">Allana's Academy of Dance</a></em></h4>
        </div>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link disabled href="/">Donate<br /><small>(coming soon)</small></Nav.Link>
            <Nav.Link href="/events">Events</Nav.Link>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  )
}

export default Header;
