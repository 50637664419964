import * as React from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Page from './components/layout/Page';
import Home from './components/Home';
import Admin from './components/Admin';
import Order from './components/Order';
// import Events from './components/Events';
import 'bootstrap/dist/css/bootstrap.min.css';

const initialPayPalOptions = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: 'USD',
  intent: 'capture',
};

const App = () => {
  return (
    <PayPalScriptProvider options={initialPayPalOptions} >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Page />}>
            <Route index element={<Home />} />
            {/* <Route path="order" element={<Order />} /> */}
            {/* <Route path="events" element={<Events />} /> */}
            {/* <Route path="blogs" element={<Blogs />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} /> */}
            <Route path="admin" element={<Admin />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </PayPalScriptProvider>
  );
}

export default App;
