import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';

import './index.scss';

const Admin = () => {

  const [authenticated, setAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [orderData, setOrderData] = React.useState([]);

  const getData = async() => {
    setAuthenticated(true);
    setLoading(true);
    const response = await axios.get('/api/orders');
    setOrderData(JSON.parse(JSON.stringify(response.data)));
    setLoading(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { formPassword } = event.target;
    if (formPassword.value === process.env.REACT_APP_ADMIN_PASSWORD) {
      getData();
    }
  };

  const fulfillOrder = async (item) => {
    if (item.isFulfilled) {
      await axios.put('/api/orders/not_fulfilled', {
        id: item._id,
      });
    } else {
      await axios.put('/api/orders/fulfilled', {
        id: item._id,
      });
    }
    getData();
  }

  return !authenticated ? (
    <Container className="text-center">
      <strong>Please enter admin password:</strong>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Control required type="password" placeholder="Enter Admin Password" />
        </Form.Group>
        <Button type="submit">Submit</Button>
      </Form>
    </Container>
  ) : (
    <Container>
      <h2 className="py-3">Orders</h2>
      <table className="signup-table">
        <thead>
          <tr>
            <th>Dancer</th>
            <th>Dancer Level/Class</th>
            <th>Purchaser</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Order Total</th>
            <th>Item Purchased</th>
            <th>Paid</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Spinner />
          ) : orderData.map((item, index) => (
            <tr key={`order-${index}`}>
              <td>{item.formDancerName}</td>
              <td>{item.formDancerLevel}</td>
              <td>{item.formPurchaserName}</td>
              <td>{item.formEmail}</td>
              <td>{item.formPhone}</td>
              <td>{item.price}</td>
              <td>{item.cartItem.title} </td>
              <td className={item.paymentStatus === 'COMPLETED' ? 'text-success' : 'text-danger'}>{item.paymentStatus}</td>
              <td className={item.isFulfilled ? 'text-success' : 'text-danger'}>{item.isFulfilled ? 'Fulfilled' : 'Not fulfilled'}</td>
              <td><Button onClick={() => fulfillOrder(item)}>{item.isFulfilled ? 'Reset': 'Fulfill'}</Button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  )
}

export default Admin;